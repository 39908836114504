<template>
  <v-app>
    <!-- <pages-core-app-bar /> -->

    <four0four-view />

    <four0four-footer />
  </v-app>
</template>

<script>
  export default {
    name: 'PagesIndex',

    components: {
      // Four0fourAppBar: () => import('@/layouts/404/AppBar'),
      Four0fourFooter: () => import('@/layouts/404/Footer'),
      Four0fourView: () => import('@/layouts/404/View'),
    },

    created () {
      this.$vuetify.theme.dark = true
    },

    beforeDestroy () {
      this.$vuetify.theme.dark = false
    },
  }
</script>
